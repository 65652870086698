import { config } from "@utils";
import { MenuName } from "../../../Navigation/components/Navbar/Navbar.types";
import { FooterLinks } from "./links.types";
import { DynamicRoutes, ExternalRoutes, StaticRoutes } from "@routes";
import { TRUST_CENTER_URL } from "utils/_constants";

const usProductLinks = [
  {
    link: StaticRoutes.ourSolutionsIndividuals,
    text: "For Individuals",
  },
  {
    link: StaticRoutes.ourSolutionsBusinesses,
    text: "For Businesses",
  },
  {
    link: StaticRoutes.ourSolutionsInsuranceCarriers,
    text: "For Insurance Carriers",
  },
  {
    link: StaticRoutes.ourSolutionsBrokersAndAdvisers,
    text: "For Brokers and Advisers",
  },
  {
    link: StaticRoutes.insuranceGroupInsurance,
    text: "Group Insurance",
  },
  {
    link: StaticRoutes.insuranceVoluntaryInsurance,
    text: "Voluntary Insurance",
  },
  {
    link: StaticRoutes.insuranceAppOnly,
    text: "App Only",
  },
];

const usFooterLinks: FooterLinks = [
  {
    title: MenuName.solutions,
    id: "products-footer-title",
    links: usProductLinks,
  },
  {
    title: MenuName.features,
    id: "features-footer-title",
    links: [
      {
        link: StaticRoutes.featuresEmployeeApp,
        text: "Employee App",
      },
      {
        link: StaticRoutes.featuresEmployeeEngagement,
        text: "Employee Engagement",
      },
      {
        link: StaticRoutes.featuresPortal,
        text: "Employer Portal",
      },
      {
        link: StaticRoutes.featuresWellbeingHub,
        text: "Wellbeing Hub",
      },
      {
        link: StaticRoutes.featuresRewards,
        text: "Incentives & Rewards",
      },
      {
        link: StaticRoutes.featuresSocialImpactBenefits,
        text: "Social Impact (ESG)",
      },
      {
        link: StaticRoutes.featuresHrInsights,
        text: "Reporting",
      },
    ],
  },
  {
    title: MenuName.resources,
    id: "resources-footer-title",
    links: [
      {
        link: StaticRoutes.resourceHub,
        text: "Resource Hub",
      },
      {
        link: StaticRoutes.caseStudies,
        text: "Case Studies",
      },
      {
        link: StaticRoutes.blog,
        text: "Blogs",
      },
      {
        link: StaticRoutes.newsAndEvents,
        text: "News & Events",
      },
      {
        link: ExternalRoutes.faq,
        text: "FAQ",
        external: true,
      },
    ],
  },
  {
    title: MenuName.about,
    id: "about-footer-title",
    links: [
      {
        link: StaticRoutes.aboutUs,
        text: "About Us",
      },
      {
        link: StaticRoutes.team,
        text: "Our Team",
      },
      {
        link: StaticRoutes.press,
        text: "Press",
      },
      {
        link: StaticRoutes.careers,
        text: "Careers",
      },
      {
        link: TRUST_CENTER_URL,
        text: "Trust Center",
        external: true,
      },
    ],
  },
  {
    title: MenuName.login,
    id: "login-footer-title",
    links: [
      {
        link: `${config.TEAM_URL}/login?region=US`,
        text: "Admin",
        external: true,
      },
      {
        link: `${config.MEMBERS_URL}/login?region=US`,
        text: "Member",
        external: true,
      },
    ],
  },
  {
    title: MenuName.legal,
    id: "legal-footer-title",
    links: [
      {
        link: DynamicRoutes.privacyPolicy,
        text: "Privacy Policy",
        locale: "us",
      },
      // Rewards policy temporarily removed at the request of legal
      // {
      //   link: DynamicRoutes.rewardsPolicy,
      //   text: "Rewards policy",
      //   locale: "us",
      // },
      {
        link: DynamicRoutes.websiteTermsAndConditions,
        text: "Terms & Conditions",
        locale: "us",
      },
      {
        link: DynamicRoutes.endUserLicenseAgreementPolicyUS,
        text: "End User Licence Agreement",
        locale: "us",
      },
    ],
  },
];

export default usFooterLinks;
