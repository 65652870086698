import { AnnouncementVariants, AnnouncementVariantsMap } from "./types";

export const announcementVariants: AnnouncementVariantsMap = {
  [AnnouncementVariants.HubspotMigration]: {
    mainText:
      "Heads up! We’re performing routine maintenance from 20/12 to 23/12. Some website forms may not work during this time. Thanks for your patience!",
    startDate: new Date("2024-12-20"),
    endDate: new Date("2024-12-23"),
    locales: ["uk", "za", "us"],
  },
  [AnnouncementVariants.IntWomansDay]: {
    mainText:
      "Join us for an unmissable virtual panel for International Women’s Day | Thursday 6th March 9.30am",
    startDate: new Date("2025-02-17"),
    endDate: new Date("2025-03-06"),
    link: "https://eu1.hubs.ly/H0gSGWD0",
    linkText: "Register now",
    locales: ["uk", "za", "us"],
  },
};
