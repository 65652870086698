import { FC } from "react";
import styled from "styled-components";
import { mobile } from "components/templates/Breakpoints";

interface Props {
  isMinimized?: boolean;
  id?: string;
}

export const DaiIchiYuLifeDoubleLogo: FC<React.PropsWithChildren<Props>> = ({
  isMinimized = false,
}) => (
  <LogoContainer>
    <LogoImage src="/static/logos/YuLifeMenu.png" alt="YuLife Logo" $isMinimized={isMinimized} />
    <Divider $isMinimized={isMinimized} />
    <LogoImage
      src="/static/logos/DaiIchiMenu.png"
      alt="Dai-Ichi Life Logo"
      $isMinimized={isMinimized}
    />
  </LogoContainer>
);

const LogoImage = styled.img<{ $isMinimized?: boolean }>`
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  width: ${(p) => (p.$isMinimized ? "5rem" : "7rem")};

  ${mobile} {
    width: ${(p) => (p.$isMinimized ? "3.5rem" : "8rem")};
  }

  @media only screen and (max-width: 30rem) {
    width: ${(p) => (p.$isMinimized ? "3rem" : "5.125rem")};
    height: auto;
  }
`;

const Divider = styled.div<{ $isMinimized?: boolean }>`
  width: 1px;
  background-color: #d9d9d7;
  height: ${(p) => (p.$isMinimized ? "3.5rem" : "4.5rem")};
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);

  ${mobile} {
    height: ${(p) => (p.$isMinimized ? "1.5rem" : "4rem")};
  }

  @media only screen and (max-width: 30rem) {
    height: ${(p) => (p.$isMinimized ? "1.5rem" : "3.5rem")};
  }
`;

const LogoContainer = styled.div`
  display: flex;
  gap: 1.75rem;
  align-items: center;
`;
