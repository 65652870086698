import styled, { css } from "styled-components";
import { color } from "@styles";
import React, {
  FC,
  createElement,
  forwardRef,
  HTMLProps,
  MouseEventHandler,
  PropsWithChildren,
} from "react";
import { Link } from "@components/atoms";
import { mobile } from "components/templates/Breakpoints";

export enum buttonVariant {
  primary = "primary",
  secondary = "secondary",
  secondaryInverted = "secondary-inverted",
  tertiary = "tertiary",
  tertiaryInverted = "tertiary-inverted",
  epic = "epic",
  epicInverted = "epic-inverted",
  rare = "rare",
  mini = "mini",
  transparent = "transparent",
  epicTransparent = "epic-transparent",
  text = "text",
}

type ButtonVariantProps = PropsWithChildren<
  { [x: string]: any } & HTMLProps<HTMLButtonElement> & {
      tag?: string;
    }
>;

const getButton = (variant: buttonVariant, props: ButtonVariantProps) => {
  switch (variant) {
    case buttonVariant.epic:
      return <EpicButton {...props} />;
    case buttonVariant.epicInverted:
      return <EpicInvertedButton {...props} />;
    case buttonVariant.epicTransparent:
      return <EpicTransparentButton {...props} />;
    case buttonVariant.rare:
      return <RareButton {...props} />;
    case buttonVariant.mini:
      return <MiniButton {...props} />;
    case buttonVariant.tertiaryInverted:
      return <TertiaryInvertedButton {...props} />;
    case buttonVariant.text:
      return <TextButton {...props} />;
    default:
      return <StyledButton {...props} />;
  }
};

interface Props {
  variant?: buttonVariant;
  isdisabled?: boolean;
  minwidth?: string;
  url?: string;
  newTab?: boolean;
  onClick?: Function & MouseEventHandler<HTMLButtonElement>;
  style?: object;
  className?: string;
}

const ButtonVariant = forwardRef<unknown, ButtonVariantProps>((props, _ref) => {
  return getButton(props.variant, props);
});

ButtonVariant.displayName = "ButtonVariant";

export const Button: FC<React.PropsWithChildren<Props>> = forwardRef(
  (
    { variant, isdisabled, children, minwidth = "16.5rem", url, onClick, style, className },
    ref,
  ) => {
    if (url) {
      const isUrlExternal = /^https?/.test(url) || /^mailto:?/.test(url);

      if (isUrlExternal) {
        return (
          <ButtonVariant
            ref={ref}
            variant={variant}
            tag="a"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            isdisabled={isdisabled}
            minwidth={minwidth}
            style={style}
            className={className}
          >
            {children}
          </ButtonVariant>
        );
      }

      return (
        <Link href={url} passHref legacyBehavior>
          <ButtonVariant
            ref={ref}
            tag="a"
            variant={variant}
            href={url}
            isdisabled={isdisabled}
            minwidth={minwidth}
            style={style}
            className={className}
          >
            {children}
          </ButtonVariant>
        </Link>
      );
    }

    return (
      <ButtonVariant
        ref={ref}
        variant={variant}
        onClick={onClick}
        isdisabled={isdisabled}
        minwidth={minwidth}
        style={style}
        className={className}
      >
        {children}
      </ButtonVariant>
    );
  },
);

Button.displayName = "Button";

const BaseButton = css`
  height: 4rem;
  margin: 2rem 0.5rem 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem 1.5rem 0 1.5rem;
  box-sizing: border-box;
  border: none;
  border-radius: 0.5rem;
  font-weight: bold;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
  letter-spacing: 0.075rem;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  z-index: 2;

  @media only screen and (max-width: 52rem) {
    font-size: 1.1rem;
    padding: 0.1rem 1rem 0 1rem;
  }
`;

export const StyledButton = styled(({ tag = "button", children, ...p }) =>
  createElement(tag, p, children),
)<{
  variant?: buttonVariant;
  isdisabled?: boolean;
  minwidth?: string;
}>`
  ${BaseButton}
  min-width: ${(p) => p.minwidth || "16.5rem"};
  width: fit-content;

  ${mobile} {
    min-width: ${(p) => p.minwidth || "16.5rem"};
    width: 100%;
  }

  @media only screen and (max-width: 65rem) {
    margin-top: 0;
  }

  @media only screen and (max-width: 20rem) {
    min-width: 0;
  }

  color: ${(p) =>
    p.variant
      ? p.variant === "secondary"
        ? color.P100
        : p.variant === "secondary-inverted"
          ? color.S1100
          : p.variant === "tertiary"
            ? "#956AFF"
            : p.variant === "transparent"
              ? color.S1100
              : "#ffffff"
      : "#ffffff"};
  background-color: ${(p) =>
    p.variant
      ? p.variant === "secondary"
        ? color.P10
        : p.variant === "secondary-inverted"
          ? "#ffffff"
          : p.variant === "tertiary"
            ? "#ffffff"
            : p.variant === "transparent"
              ? "transparent"
              : color.S1100
      : color.P100};
  border: 2px solid
    ${(p) =>
      p.variant
        ? p.variant === "secondary"
          ? color.P100
          : p.variant === "secondary-inverted"
            ? "#ffffff"
            : p.variant === "tertiary"
              ? "#ffffff"
              : color.S1100
        : color.P100};

  &:hover {
    box-shadow: 0.5rem 0.5rem 0 0
      ${(p) =>
        p.variant === "tertiary" || p.variant === "transparent"
          ? "rgba(255, 255, 255, 0.2)"
          : color.P20};
    transform: translate(-0.5rem, -0.5rem);

    color: ${(p) =>
      p.variant
        ? p.variant === "secondary"
          ? color.P100
          : p.variant === "secondary-inverted"
            ? "#ffffff"
            : p.variant === "transparent"
              ? color.S1100
              : "#ffffff"
        : "#ffffff"};
    background-color: ${(p) =>
      p.variant
        ? p.variant === "secondary"
          ? color.P10
          : p.variant === "secondary-inverted"
            ? color.S130
            : p.variant === "tertiary"
              ? "rgba(255, 255, 255, 0.2)"
              : p.variant === "transparent"
                ? "rgba(255, 255, 255, 0.2)"
                : color.S1100
        : color.P100};
    border: 2px solid
      ${(p) =>
        p.variant
          ? p.variant === "secondary"
            ? color.P100
            : p.variant === "secondary-inverted"
              ? color.S130
              : p.variant === "tertiary"
                ? "#ffffff"
                : p.variant === "transparent"
                  ? "color.S1100"
                  : color.S150
          : color.P100};
  }

  ${(p) =>
    !p.isdisabled
      ? null
      : `
    color: ${(p) =>
      p.variant
        ? p.variant === "secondary"
          ? color.S1100
          : p.variant === "secondary-inverted"
            ? color.S1100
            : "#ffffff"
        : "#ffffff"};
    background: ${color.S130};
    border: 2px solid ${color.S130};
    cursor: not-allowed;
  `};
`;

const TextButton = styled(({ tag = "button", children, ...p }) => createElement(tag, p, children))`
  ${BaseButton}
  background: none;
  border: none;
  color: ${color.P100};
  font-weight: 700;
  cursor: pointer;
  letter-spacing: 0.075rem;
  line-height: 1.6rem;
  padding: 0 0.5rem;

  &:hover {
    text-decoration: none;
  }
`;

const TertiaryInvertedButton = styled(({ tag = "button", children, ...p }) =>
  createElement(tag, p, children),
)<{
  isdisabled?: boolean;
  minwidth?: string;
}>`
  ${BaseButton}
  min-width: ${(p) => p.minwidth || "16.5rem"};
  width: fit-content;
  color: ${color.white};
  background-color: ${color.S3100};
  border: 2px solid ${color.white};

  &:hover {
    box-shadow: 0.5rem 0.5rem 0 0 ${color.S350};
    transform: translate(-0.5rem, -0.5rem);
    color: ${color.white};
    background-color: ${color.S3100};
  }

  ${(p) =>
    !p.isdisabled
      ? null
      : `
    color: ${color.white};
    background: ${color.S350};
    border: 2px solid ${color.S350};
    cursor: not-allowed;
  `};

  @media only screen and (max-width: 65rem) {
    width: 100%;
  }
`;

const EpicButton = styled(({ tag = "button", children, ...p }) => createElement(tag, p, children))<{
  isdisabled?: boolean;
  minwidth?: string;
}>`
  ${BaseButton}
  min-width: ${(p) => p.minwidth || "16.5rem"};
  width: fit-content;
  color: ${color.S3100};
  background-color: #ffffff;
  border: 2px solid ${color.S3100};

  &:hover {
    box-shadow: 0.5rem 0.5rem 0 0 ${color.S350};
    transform: translate(-0.5rem, -0.5rem);

    color: #ffffff;
    background-color: ${color.S3100};
    border: 2px solid #ffffff;
  }

  ${(p) =>
    !p.isdisabled
      ? null
      : `
    color: #ffffff;
    background: ${color.S350};
    border: 2px solid ${color.S350};
    cursor: not-allowed;
  `};

  @media only screen and (max-width: 65rem) {
    width: 100%;
  }
`;

const EpicInvertedButton = styled(({ tag = "button", children, ...p }) =>
  createElement(tag, p, children),
)<{
  isdisabled?: boolean;
  minwidth?: string;
}>`
  ${BaseButton}
  min-width: ${(p) => p.minwidth || "16.5rem"};
  width: fit-content;
  color: ${color.white};
  background-color: ${color.S3100};
  border: 2px solid ${color.S3100};

  &:hover {
    box-shadow: 0.5rem 0.5rem 0 0 ${color.S350};
    transform: translate(-0.5rem, -0.5rem);
    color: ${color.white};
    background-color: ${color.S3100};
  }

  ${(p) =>
    !p.isdisabled
      ? null
      : `
    color: ${color.white};
    background: ${color.S350};
    border: 2px solid ${color.S350};
    cursor: not-allowed;
  `};

  @media only screen and (max-width: 65rem) {
    width: 100%;
  }
`;

const EpicTransparentButton = styled(({ tag = "button", children, ...p }) =>
  createElement(tag, p, children),
)<{
  isdisabled?: boolean;
  minwidth?: string;
}>`
  ${BaseButton}
  min-width: ${(p) => p.minwidth || "16.5rem"};
  width: fit-content;
  color: ${color.S3100};
  background-color: transparent;
  border: 2px solid ${color.S3100};

  &:hover {
    box-shadow: 0.5rem 0.5rem 0 0 ${color.S350};
    transform: translate(-0.5rem, -0.5rem);
    color: ${color.white};
    background-color: ${color.S3100};
  }

  ${(p) =>
    !p.isdisabled
      ? null
      : `
    color: ${color.white};
    background: ${color.S350};
    border: 2px solid ${color.S350};
    cursor: not-allowed;
  `};

  @media only screen and (max-width: 65rem) {
    width: 100%;
  }
`;

const RareButton = styled(({ tag = "button", children, ...p }) => createElement(tag, p, children))<{
  isdisabled?: boolean;
  minwidth?: string;
}>`
  ${BaseButton}
  min-width: ${(p) => p.minwidth || "16.5rem"};
  width: fit-content;
  color: ${color.S2100};
  background-color: #ffffff;
  border: 2px solid ${color.S2100};

  &:hover {
    box-shadow: 0.5rem 0.5rem 0 0 ${color.S250};
    transform: translate(-0.5rem, -0.5rem);

    color: #ffffff;
    background-color: ${color.S2100};
    border: 2px solid #ffffff;
  }

  ${(p) =>
    !p.isdisabled
      ? null
      : `
    color: #ffffff;
    background: ${color.S350};
    border: 2px solid ${color.S350};
    cursor: not-allowed;
  `};

  @media only screen and (max-width: 65rem) {
    width: 100%;
  }
`;

const MiniButton = styled(({ tag = "button", children, ...p }) => createElement(tag, p, children))<{
  isdisabled?: boolean;
  minwidth?: string;
}>`
  ${BaseButton}
  height: 2rem;
  margin: 0;
  display: inline-flex;
  font-size: 0.875rem;
  color: ${color.S3100};
  background-color: #ffffff;
  border: 2px solid ${color.S3100};

  &:hover {
    box-shadow: 0.2rem 0.2rem 0 0 ${color.S350};
    transform: translate(-0.2rem, -0.2rem);

    color: #ffffff;
    background-color: ${color.S3100};
    border: 2px solid #ffffff;
  }

  ${(p) =>
    !p.isdisabled
      ? null
      : `
  color: #ffffff;
  background: ${color.S350};
  border: 2px solid ${color.S350};
  cursor: not-allowed;
`};

  @media only screen and (max-width: 50rem) {
    width: 100%;
    height: 4rem;
  }
`;
