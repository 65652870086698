import { config } from "@utils";
import { MenuName } from "../../../Navigation/components/Navbar/Navbar.types";
import { FooterLinks } from "./links.types";
import { StaticRoutes, ExternalRoutes, DynamicRoutes } from "@routes";
import { TRUST_CENTER_URL } from "utils/_constants";

const zaFooterLinks: FooterLinks = [
  {
    title: MenuName.products,
    id: "products-footer-title",
    links: [
      {
        link: StaticRoutes.insuranceGroupLifeInsurance,
        text: "Life Cover",
      },
      {
        link: StaticRoutes.insuranceGroupIncomeProtection,
        text: "Income Protection",
      },
      {
        link: StaticRoutes.insuranceFuneralCover,
        text: "Funeral Cover",
      },
      {
        link: StaticRoutes.insuranceLumpSumDisability,
        text: "Lump Sum Disability",
      },
      {
        link: StaticRoutes.insuranceGroupCriticalIllness,
        text: "Critical Illness",
      },
      {
        link: StaticRoutes.insuranceEducationBenefit,
        text: "Education Benefit",
      },
    ],
  },
  {
    title: MenuName.features,
    id: "features-footer-title",
    links: [
      {
        link: StaticRoutes.featuresEmployeeApp,
        text: "Employee App",
      },
      {
        link: StaticRoutes.featuresPortal,
        text: "HR Portal",
      },
      {
        link: StaticRoutes.featuresWellbeingHub,
        text: "Wellbeing Hub",
      },
      {
        link: StaticRoutes.featuresRewards,
        text: "Incentives & Rewards",
      },
      {
        link: StaticRoutes.featuresEmployeeAssistanceProgramme,
        text: "Mental Health (EAP)",
      },
      {
        text: "Virtual GP",
        link: StaticRoutes.featuresVirtualGpServices,
      },
      {
        link: StaticRoutes.featuresHrInsights,
        text: "Reporting",
      },
      {
        link: StaticRoutes.featuresSocialImpactBenefits,
        text: "Social Impact (ESG)",
      },
    ],
  },
  {
    title: MenuName.resources,
    id: "resources-footer-title",
    links: [
      {
        link: StaticRoutes.resourceHub,
        text: "Resource Hub",
      },
      {
        link: StaticRoutes.caseStudies,
        text: "Case Studies",
      },
      {
        link: StaticRoutes.blog,
        text: "Blogs",
      },
      {
        link: StaticRoutes.newsAndEvents,
        text: "News & Events",
      },
      {
        link: StaticRoutes.groupInsuranceForms,
        text: "Claim Forms",
      },
      {
        link: ExternalRoutes.faq,
        text: "FAQ",
        external: true,
      },
    ],
  },
  {
    title: MenuName.about,
    id: "about-footer-title",
    links: [
      {
        link: StaticRoutes.aboutUs,
        text: "About Us",
      },
      {
        link: StaticRoutes.team,
        text: "Our Team",
      },
      {
        link: StaticRoutes.press,
        text: "Press",
      },
      {
        link: StaticRoutes.careers,
        text: "Careers",
      },
      {
        link: StaticRoutes.contactUs,
        text: "Contact Us",
      },
      {
        link: TRUST_CENTER_URL,
        text: "Trust Centre",
        external: true,
      },
    ],
  },
  {
    title: MenuName.login,
    id: "login-footer-title",
    links: [
      {
        link: `${config.TEAM_URL}/login`,
        text: "Employers",
        external: true,
      },
      {
        link: `${config.MEMBERS_URL}/login`,
        text: "Employees",
        external: true,
      },
    ],
  },
  {
    title: MenuName.legal,
    id: "legal-footer-title",
    links: [
      {
        link: DynamicRoutes.privacyPolicy,
        text: "Privacy Policy",
      },
      {
        link: DynamicRoutes.cookiePolicy,
        text: "Cookie Policy",
      },
      {
        link: DynamicRoutes.complaints,
        text: "Complaints",
        external: true,
      },
      {
        link: "/static/docs/za/policy-guide/1.0.0/policy-guide.pdf",
        text: "Policy guide",
        locale: "uk",
      },
      {
        link: "/static/docs/za/paia-manual/paia-manual.pdf",
        text: "PAIA Manual",
        locale: "uk",
      },
      {
        link: "/static/docs/za/app-user-terms/app-user-terms.pdf",
        text: "App User Terms",
        locale: "uk",
      },
      // Rewards policy temporarily removed at the request of legal
      // {
      //   link: DynamicRoutes.rewardsPolicy,
      //   text: "Rewards policy",
      // },
    ],
  },
];

export default zaFooterLinks;
