"use client";

import { useMemo } from "react";
import { useLocale } from "./useLocale";

export const useLocaleData = <T>(data: Record<string, T>) => {
  const { locale } = useLocale();

  const localeData = useMemo(() => data[locale] ?? data["uk"], [data, locale]);
  return localeData;
};
