import { config } from "@utils";

import { NavIconNames } from "../../../../atoms/NavIcon";
import { MenuName, NavLinks } from "./Navbar.types";
import { StaticRoutes, DynamicRoutes } from "@routes";

const zaNavLinks: NavLinks = [
  {
    title: MenuName.products,
    id: "products-nav-title",
    primaryLinks: [
      {
        link: StaticRoutes.insuranceGroupLifeInsurance,
        text: "Life Cover",
      },
      {
        link: StaticRoutes.insuranceGroupIncomeProtection,
        text: "Income Protection",
      },
      {
        link: StaticRoutes.insuranceFuneralCover,
        text: "Funeral Cover",
      },
      {
        link: StaticRoutes.insuranceLumpSumDisability,
        text: "Lump Sum Disability",
      },
      {
        link: StaticRoutes.insuranceGroupCriticalIllness,
        text: "Critical Illness",
      },
      {
        link: StaticRoutes.insuranceEducationBenefit,
        text: "Education Benefit",
        isLive: false,
      },
      {
        link: StaticRoutes.whatsIncluded,
        text: "What's included",
      },
    ],

    secondaryLinks: [
      {
        link: StaticRoutes.featuresEmployeeApp,
        text: "Employee App",
      },
      {
        link: StaticRoutes.featuresRewards,
        text: "Incentives & Rewards",
      },
      {
        link: StaticRoutes.featuresEmployeeAssistanceProgramme,
        text: "Mental Health (EAP)",
      },
      {
        text: "Virtual GP",
        link: StaticRoutes.featuresVirtualGpServices,
      },
      {
        link: StaticRoutes.featuresWellbeingHub,
        text: "Wellbeing Hub",
      },
      {
        link: StaticRoutes.featuresSocialImpactBenefits,
        text: "Social Impact (ESG)",
      },
      {
        link: StaticRoutes.featuresHrInsights,
        text: "Reporting",
      },
      {
        link: StaticRoutes.featuresPortal,
        text: "Employer Portal",
      },
    ],
    secondaryTitle: MenuName.features,
  },
  {
    title: MenuName.resources,
    id: "resources-nav-title",
    primaryLinks: [
      {
        link: StaticRoutes.resourceHub,
        text: "Resource Hub",
      },
      {
        link: StaticRoutes.blog,
        text: "Blogs",
      },
      {
        link: StaticRoutes.caseStudies,
        text: "Case Studies",
      },
      {
        link: StaticRoutes.newsAndEvents,
        text: "News & Events",
      },
      {
        link: StaticRoutes.groupInsuranceForms,
        text: "Claim Forms",
      },
    ],
  },
  {
    title: MenuName.about,
    id: "about-nav-title",
    primaryLinks: [
      {
        link: StaticRoutes.aboutUs,
        text: "About Us",
      },
      {
        link: StaticRoutes.careers,
        text: "Careers",
      },
      {
        link: StaticRoutes.press,
        text: "Press",
      },
      {
        link: StaticRoutes.contactUs,
        text: "Contact",
      },
    ],
  },
  {
    title: MenuName.login,
    id: "login-nav-title",
    primaryLinks: [
      {
        link: `${config.TEAM_URL}/login?region=SA`,
        text: "Employers",
        external: true,
        iconColor: "S3100",
        iconType: NavIconNames.hrLogin,
      },
      {
        link: `${config.MEMBERS_URL}/login?region=SA`,
        text: "Employees",
        external: true,
        iconColor: "S2100",
        iconType: NavIconNames.employeeLogin,
      },
    ],
  },
  {
    title: MenuName.region,
    id: "login-nav-title",
    primaryLinks: [
      {
        locale: "uk",
        localeLink: true,
        link: "GB-UKM",
        text: "UK",
      },
      {
        locale: "us",
        localeLink: true,
        text: "US",
        link: "US",
      },
      {
        locale: "za",
        localeLink: true,
        text: "SA",
        link: "ZA",
      },
      // commenting out until Japan launch
      // {
      //   locale: "jp",
      //   localeLink: true,
      //   text: "JP",
      //   link: "JP",
      // },
    ],
  },
];

export default zaNavLinks;
