import type { JSX } from "react";
import { P1 } from "components/atoms/Paragraph";
import { Arrow } from "components/atoms/svgs/Arrow";
import Link from "next/link";
import { FC } from "react";
import { AnnouncementBarContainer, Img, LeftSection, LinkContainer, RightSection } from "./styles";
import { useScrollThreshold } from "@hooks";

export type AnnouncementVariant = {
  mainText: string | JSX.Element;
  linkText?: string;
  link?: string;
  backgroundColor?: string;
  textColor?: string;
  image?: string;
  imageAlt?: string;
  startDate: Date;
  endDate: Date;
  locales: string[];
  pageStyles?: boolean;
};

export const AnnouncementBar: FC<AnnouncementVariant> = ({
  backgroundColor,
  mainText,
  textColor,
  link,
  linkText,
  image,
  imageAlt,
  pageStyles = true,
}) => {
  const isScrolled = useScrollThreshold();

  const Announcement = (
    <AnnouncementBarContainer
      $pageStyles={pageStyles}
      $isScrolled={isScrolled}
      $backgroundColor={backgroundColor}
    >
      <LeftSection>
        {typeof mainText === "string" ? (
          <P1 pt="0" pb="0" color={textColor || "#FFF"} fontSize="1rem" lineHeight="1.5rem">
            {mainText}
          </P1>
        ) : (
          mainText
        )}
      </LeftSection>
      <RightSection>
        {linkText && (
          <LinkContainer>
            <P1
              pt="0"
              pr="0.5rem"
              color={textColor || "#FFF"}
              bold={true}
              fontSize="1rem"
              lineHeight="1.5rem"
            >
              {linkText}
            </P1>
            <Arrow />
          </LinkContainer>
        )}
        {image && <Img src={image} alt={imageAlt} height={56} width={64} />}
      </RightSection>
    </AnnouncementBarContainer>
  );

  return link ? (
    <Link href={link} target="_blank" rel="noopener noreferrer">
      {Announcement}
    </Link>
  ) : (
    Announcement
  );
};
