import { FC, useState } from "react";
import { EarthContainer, Padding, StyledFieldset } from "./styles";
import { CloseButton } from "../../styles";
import { color, color as themeColor } from "@styles";
import { useHandleLocaleSwitch, useLocale, YuLifeLocale } from "hooks";
import { BaseModal } from "../../components/BaseModal";
import { LanguageSwitcher } from "components/organisms/LanguageSwitcher";
import { Cross } from "components/atoms/svgs/Cross";
import { usePathname } from "next/navigation";
import { useRouter as usePagesRouter } from "next/router";
import { useLocaleData } from "hooks/useLocaleData";
import data from "./translations";

const CLOSE_TIMING = 1200; // ms

interface Props {
  openModal: boolean;
  closeModal: () => void;
}

export const UserLocaleModal: FC<Props> = ({ openModal, closeModal }) => {
  const { path } = useLocale();
  const [isClosing, setIsClosing] = useState(false);
  const { paragraph, languageOptions } = useLocaleData(data);
  const handleLocaleSwitch = useHandleLocaleSwitch();

  return (
    <BaseModal
      background={themeColor.B220}
      closeModal={closeModal}
      closeOnClickOutside={false}
      showModal={openModal}
      maxWidth="50rem"
    >
      <>
        <Padding spacing="1rem">
          <StyledFieldset>
            {paragraph}
            <LanguageSwitcher
              altLangs={languageOptions}
              path={path}
              onClick={(e, country: YuLifeLocale) => {
                e.preventDefault();
                setIsClosing(true);

                setTimeout(() => {
                  closeModal();
                  setIsClosing(false);
                  handleLocaleSwitch(country);
                }, CLOSE_TIMING);
              }}
            />
          </StyledFieldset>
        </Padding>
        <EarthContainer isClosing={isClosing} closeTiming={CLOSE_TIMING}>
          <img src="/static/svg/earth.svg" alt="Earth" height={312} width={312} />
        </EarthContainer>
        <CloseButton onClick={closeModal} aria-label="close">
          <Cross color={color.B1100} />
        </CloseButton>
      </>
    </BaseModal>
  );
};
